/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
// import { Image, TabList, Tab, SelectTabEvent, SelectTabData, TabValue } from '@fluentui/react-components';
import './Welcome.css';
// import { EditCode } from "./EditCode";
import { app, authentication } from '@microsoft/teams-js';
// import { AzureFunctions } from "./AzureFunctions";
// import { Graph } from "./Graph";
// import { CurrentUser } from "./CurrentUser";
import { useData } from '@microsoft/teamsfx-react';
// import { Deploy } from "./Deploy";
// import { Publish } from "./Publish";
import { TeamsFxContext } from '../Context';

export function Welcome(props: { showFunction?: boolean; environment?: string }) {
  // const { showFunction, environment } = {
  //   showFunction: true,
  //   environment: window.location.hostname === "localhost" ? "local" : "azure",
  //   ...props,
  // };

  // const friendlyEnvironmentName =
  //   {
  //     local: "local environment",
  //     azure: "Azure environment",
  //   }[environment] || "local environment";

  // const [selectedValue, setSelectedValue] = useState<TabValue>("local");

  const [userToken, setUserToken] = useState<string>();

  // const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
  //   setSelectedValue(data.value);
  // };

  const { teamsUserCredential } = useContext(TeamsFxContext);

  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential && (typeof userToken !== 'string' || (typeof userToken === 'string' && !userToken.length))) {
      const userInfo = await teamsUserCredential.getUserInfo();

      try {
        var authTokenRequest: authentication.AuthTokenRequestParameters = {
          silent: true,
        };
        const authToken = await authentication.getAuthToken(authTokenRequest);
        setUserToken(authToken);
      } catch (e) {
        console.log(e);
        // setUserToken(JSON.stringify(e));
      }

      return userInfo;
    }
  });

  const userName = loading || error ? '' : data!.displayName;

  const hubName = useData(async () => {
    await app.initialize();
    const context = await app.getContext();
    return context.app.host.name;
  })?.data;

  useEffect(() => {
    if (typeof userToken === 'string' && userToken.length) {
      window.location.href = `https://jogajuntobb-staging.funifier.app/login?agent=loginTeams&secret=${userToken}&teamsContext=true`;
    }
  }, [userToken]);

  return (
    <div className="welcome page">
      {!!loading && !userToken && <p className="loading">Carregando...</p>}

      {!!error && !userToken && (
        <p className="error">
          Ocorreu um erro na autenticação. Por favor, entre em contato com a equipe responsável ou acesse{' '}
          <a href="https://jogajuntobb.com.br" target="_blank" rel="noreferrer">
            https://jogajuntobb.com.br
          </a>
          .
        </p>
      )}

      {/* {!!userToken && !loading && <iframe title="Página do JogaJunto BB" src={`https://jogajuntobb-staging.funifier.app/login?agent=loginTeams&secret=${userToken}`} sandbox="allow-forms allow-modals allow-popups allow-scripts allow-same-origin allow-downloads allow-top-navigation" />} */}
    </div>
  );
}
